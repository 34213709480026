<template>
  <div>
    <b-table-simple
      bordered
      class="table-legend"
      v-if="
        show_campuses_ ||
        show_egress_profiles_ ||
        show_levels_ ||
        show_periods_ ||
        show_matters_ ||
        show_sections_ ||
        show_cohorts_
      "
    >
      <b-thead>
        <b-tr>
          <b-th v-if="show_campuses_ == true" style="width: 20%">
            {{ $getVisibleNames("mesh.campus", true, "Sedes").toUpperCase() }}
          </b-th>
          <b-th v-if="show_egress_profiles_ == true" style="width: 10%">
            {{
              $getVisibleNames(
                "mesh.egressprofile",
                false,
                "Perfil De Egreso"
              ).toUpperCase()
            }}
          </b-th>
          <b-th v-if="show_levels_ == true" style="width: 5%"> NIVEL </b-th>
          <b-th v-if="show_periods_ == true" style="width: 10%">
            {{
              $getVisibleNames("mesh.period", false, "Período").toUpperCase()
            }}
          </b-th>
          <b-th v-if="show_matters_ == true" style="width: 20%">
            {{
              $getVisibleNames(
                "mesh.egressprofilematter",
                false,
                "Asignatura"
              ).toUpperCase()
            }}
          </b-th>
          <b-th v-if="show_sections_ == true" style="width: 10%">
            {{
              $getVisibleNames(
                "teaching.section",
                false,
                "Sección"
              ).toUpperCase()
            }}
          </b-th>
          <b-th v-if="show_cohorts_ == true" style="width: 5%">
            Año de Ingreso
          </b-th>
          <b-th v-if="show_student_status_ == true" style="width: 10%">
            Progresión
          </b-th>
          <b-th
            v-if="cycles_.length > 0 && show_cycles_ == true"
            style="width: 10%"
          >
            {{
              $getVisibleNames(
                "mesh.cycle",
                false,
                "Ciclo Formativo"
              ).toUpperCase()
            }}
          </b-th>
        </b-tr>
      </b-thead>

      <b-tbody>
        <b-tr>
          <b-td v-if="show_campuses_">
            <span v-if="campuses_ && show_campuses_ == true">
              {{ campuses_.map((x) => x.name).join(", ") }}
            </span>
            <span v-else>-</span>
          </b-td>
          <b-td v-if="show_egress_profiles_">
            <span v-if="egress_profiles_ && show_egress_profiles_ == true">
              {{ egress_profiles_.map((x) => x.name).join(", ") }}
            </span>
            <span v-else>-</span>
          </b-td>
          <b-td v-if="show_levels_">
            <span v-if="levels_ && show_levels_ == true">
              {{ levels_.map((x) => x.name).join(", ") }}
            </span>
            <span v-else>-</span>
          </b-td>
          <b-td v-if="show_periods_">
            <span v-if="periods_ && show_periods_ == true">
              {{ periods_.map((x) => x.name).join(", ") }}
            </span>
            <span v-else>-</span>
          </b-td>
          <b-td v-if="show_matters_">
            <span v-if="matters_ && show_matters_ == true">
              {{ matters_.map((x) => x.matter_name).join(", ") }}
            </span>
            <span v-else>-</span>
          </b-td>
          <b-td v-if="show_sections_">
            <span v-if="sections_ && show_sections_ == true">
              {{ sections_.map((x) => x.name).join(", ") }}
            </span>
            <span v-else>-</span>
          </b-td>
          <b-td v-if="show_cohorts_">
            <span v-if="cohorts_ && show_cohorts_ == true">
              {{ cohorts_.map((x) => x.cohort).join(", ") }}
            </span>
            <span v-else>-</span>
          </b-td>
          <b-td v-if="show_student_status_">
            <span v-if="student_status_ && show_student_status_ == true">
              {{ student_status_.map((x) => x.name).join(", ") }}
            </span>
            <span v-else>-</span>
          </b-td>
          <b-td v-if="cycles_.length > 0 && show_cycles_">
            <span v-if="cycles_ && show_cycles_ == true">
              {{ cycles_.map((x) => x.name).join(", ") }}
            </span>
            <span v-else>-</span>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
// import * as names from "@/store/names";
// import { mapGetters } from "vuex";
export default {
  name: "LegendFilters",
  components: {
    // Filters: () => import("@/components/reports/Filters"),
  },
  props: {
    campuses_: {
      type: Array,
    },
    //Falta
    careers_: {
      type: Array,
    },
    egress_profiles_: {
      type: Array,
    },
    levels_: {
      type: Array,
    },
    matters_: {
      type: Array,
    },
    periods_: {
      type: Array,
    },
    sections_: {
      type: Array,
    },
    //Falta
    study_units_: {
      type: Array,
    },
    cohorts_: {
      type: Array,
    },
    //Falta
    cycles_: {
      type: Array,
    },
    student_status_: {
      type: Array,
    },
    show_selectors: {
      type: String,
      default: "campuses",
    },
  },
  data() {
    return {
      show_campuses_: false,
      show_careers_: false,
      show_egress_profiles_: false,
      show_levels_: false,
      show_matters_: false,
      show_periods_: false,
      show_sections_: false,
      show_study_units_: false,
      show_cohorts_: false,
      show_cycles_: false,
      show_student_status_: false,
    };
  },
  computed: {
    // ...mapGetters({}),
  },
  methods: {
    showSelector() {
      this.show_campuses_ = false;
      this.show_careers_ = false;
      this.show_egress_profiles_ = false;
      this.show_levels_ = false;
      this.show_periods_ = false;
      this.show_matters_ = false; // Asignaturas de Perfil de Egreso
      this.show_sections_ = false;
      this.show_study_units_ = false;
      this.show_cohorts_ = false;
      this.show_cycles_ = false;
      this.show_student_status_ = false;
      if (this.show_selectors.includes("campuses")) this.show_campuses_ = true;
      if (this.show_selectors.includes("careers")) this.show_careers_ = true;
      if (this.show_selectors.includes("egress_profiles"))
        this.show_egress_profiles_ = true;
      if (this.show_selectors.includes("levels")) this.show_levels_ = true;
      if (this.show_selectors.includes("periods")) this.show_periods_ = true;
      if (this.show_selectors.includes("matters")) this.show_matters_ = true;
      if (this.show_selectors.includes("sections")) this.show_sections_ = true;
      if (this.show_selectors.includes("study_units"))
        this.show_study_units_ = true;
      if (this.show_selectors.includes("cohorts")) this.show_cohorts_ = true;
      if (this.show_selectors.includes("cycles")) this.show_cycles_ = true;
      if (this.show_selectors.includes("student_status"))
        this.show_student_status_ = true;
    },
  },
  watch: {},
  mounted() {},
  created() {
    this.showSelector();
  },
};
</script>

<style>
.table-legend {
  display: none;
}
.table-legend th {
  background-color: var(--kl-menu-color) !important;
  color: white;
  align-content: center;
}
@media print {
  .table-legend {
    display: block;
  }
}
</style>